import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { getMerchants, MerchantType } from "../../../api/merchants";
import { redirect, useParams } from "react-router-dom";
import { TabPanel, TabView } from "primereact/tabview";
import MerchantProducts from "./components/MerchantProducts";
import MerchantZipCodes from "./components/MerchantZipCodes";
import MerchantOrderlord from "./components/MerchantOrderlord";
import { Button } from "primereact/button";
import MerchantInfo from "./components/MerchantInfo";
import Menu from "../MerchantMenu";
const MerchantDetails: React.FC = () => {
  const { id } = useParams();
  const { isAdmin, isFranchisee } = useAppSelector(
    (state) => state.userReducer
  );

  const [merchant, setMerchant] = useState<MerchantType>();
  const [merchants, setMerchants] = useState<MerchantType[]>();
  const [prevMerchant, setPrevMerchant] = useState<MerchantType>();
  const [nextMerchant, setNextMerchant] = useState<MerchantType>();

  useEffect(() => {
    if (id && merchant?.id !== parseInt(id)) {
      const setMerchantData = async () => {
        const response = await getMerchants();
        if (response && response.length > 0) {
          setMerchants(response);
          const sortedMerchants = response.sort((a, b) =>
            a.name > b.name ? 1 : -1
          );
          const currentIndex = sortedMerchants.findIndex(
            (m) => m.id === parseInt(id)
          );
          if (currentIndex === -1) {
            redirect("/merchants");
            return;
          }
          setMerchant(sortedMerchants[currentIndex]);
          const prevMerch = sortedMerchants[currentIndex - 1];
          const nextMerch = sortedMerchants[currentIndex + 1];
          if (prevMerch) setPrevMerchant(prevMerch);
          if (nextMerch) setNextMerchant(nextMerch);
        }
      };
      setMerchantData();
    }
  }, [id, merchant?.id]);

  return (
    <div className="card">
      <div className="border-b border-gray-200 pb-5">
        <div className="flex items-center justify-between">
          {prevMerchant ? (
            <Button
              text
              className="text-sm hover:text-gray-500"
              label={prevMerchant.name}
              icon="pi pi-angle-left"
              iconPos="left"
              onClick={() =>
                window.open(`/merchants/${prevMerchant.id}`, "_self")
              }
            />
          ) : (
            <Button
              text
              className="text-sm"
              disabled
              icon="pi pi-angle-left"
              iconPos="left"
            />
          )}
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            {merchant?.name}
          </h3>
          {nextMerchant ? (
            <Button
              text
              className="text-sm hover:text-gray-500"
              label={nextMerchant.name}
              icon="pi pi-angle-right"
              iconPos="right"
              onClick={() =>
                window.open(`/merchants/${nextMerchant.id}`, "_self")
              }
            />
          ) : (
            <Button
              text
              className="text-sm"
              disabled
              icon="pi pi-angle-right"
              iconPos="right"
            />
          )}
        </div>
        {merchant?.id && isAdmin && (
          <TabView className="border-t mt-4">
            <TabPanel header="Produkte">
              <MerchantProducts
                isAdmin={isAdmin}
                merchantId={merchant.id}
                justEatConnected={merchant.justeatIds?.length > 0}
              />
            </TabPanel>
            <TabPanel header="Details">
              <MerchantInfo
                isAdmin={isAdmin}
                merchant={merchant}
                setMerchant={setMerchant}
              />
            </TabPanel>
            <TabPanel header="Liefergebiet">
              <MerchantZipCodes isAdmin={isAdmin} merchantId={merchant.id} />
            </TabPanel>
            <TabPanel header="Orderlord">
              <MerchantOrderlord
                isAdmin={isAdmin}
                merchant={merchant}
                setMerchant={setMerchant}
                merchants={merchants}
              />
            </TabPanel>
            <TabPanel header="Justeat Menu">
              <Menu merchantId={merchant.id} />
            </TabPanel>
          </TabView>
        )}
        {merchant?.id && isFranchisee && (
          <TabView className="border-t mt-4">
            <TabPanel header="Produkte">
              <MerchantProducts
                isAdmin={isAdmin}
                merchantId={merchant.id}
                justEatConnected={merchant.justeatIds?.length > 0}
              />
            </TabPanel>
          </TabView>
        )}
      </div>
    </div>
  );
};
export default MerchantDetails;
