import Login from "../pages/auth/Login";
import Dashboard from "../pages/client/Dashboard";
import Settings from "../pages/client/Settings";
import PrivateRoutes from "./PrivateRoutes";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RequestPassword from "../pages/auth/RequestPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import { ROUTES_DEFINITIONS } from "./routerConfig";
import Merchants from "../pages/client/Merchants";
import Products from "../pages/client/Products";
import Orders from "../pages/client/Orders";
import HelpDesk from "../pages/client/HelpDesk";
import MerchantOrders from "../pages/client/MerchantOrders";
import Reset from "../pages/client/Reset";
import { addLocale, locale } from "primereact/api";
import MerchantDetails from "../pages/client/MechantDetails";
import { useAppSelector } from "../hooks/reduxHooks";

function Router() {
  const { isFranchisee } = useAppSelector((state) => state.userReducer);

  locale("de");
  addLocale("de", {
    choose: "Wählen",
  });
  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES_DEFINITIONS.Reset} element={<Reset />} />
        <Route path={ROUTES_DEFINITIONS.Login} element={<Login />} />
        <Route
          path={ROUTES_DEFINITIONS.RequestPassword}
          element={<RequestPassword />}
        />
        <Route
          path={ROUTES_DEFINITIONS.RestPassword}
          element={<ResetPassword />}
        />
        <Route element={<PrivateRoutes />}>
          {isFranchisee ? (
            <>
              <Route
                path={ROUTES_DEFINITIONS.Dashboard}
                element={<Dashboard />}
              />
              <Route path={ROUTES_DEFINITIONS.Merchants}>
                <Route index element={<Merchants />} />
                <Route path=":id" element={<MerchantDetails />} />
              </Route>
              <Route
                path={ROUTES_DEFINITIONS.Settings}
                element={<Settings />}
              />
            </>
          ) : (
            <>
              <Route
                path={ROUTES_DEFINITIONS.Dashboard}
                element={<Dashboard />}
              />
              <Route
                path={ROUTES_DEFINITIONS.Products}
                element={<Products />}
              />
              <Route path={ROUTES_DEFINITIONS.Merchants}>
                <Route index element={<Merchants />} />
                <Route path=":id" element={<MerchantDetails />} />
              </Route>
              <Route
                path={ROUTES_DEFINITIONS.MerchantOrders}
                element={<MerchantOrders />}
              />
              <Route
                path={ROUTES_DEFINITIONS.HelpDesk}
                element={<HelpDesk />}
              />
              <Route path={ROUTES_DEFINITIONS.Orders} element={<Orders />} />
              <Route
                path={ROUTES_DEFINITIONS.Settings}
                element={<Settings />}
              />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
