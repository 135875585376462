import React, { useEffect, useState } from "react";
import {
  MerchantType,
  deleteMerchant,
  getMerchants,
} from "../../../api/merchants";
import { FilterMatchMode } from "primereact/api";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Column } from "primereact/column";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { InputText } from "primereact/inputtext";
import CreateModal from "./createModal";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../hooks/reduxHooks";

interface ColumnMeta {
  field: string;
  header: string;
}

const Merchants: React.FC = () => {
  dayjs.extend(duration);
  const { isAdmin } = useAppSelector((state) => state.userReducer);
  const [currentMerchant, setCurrentMerchant] = useState<MerchantType>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [merchants, setMerchants] = useState<MerchantType[]>([]);
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const columns: ColumnMeta[] = [
    { field: "name", header: "Name" },
    { field: "latitude", header: "Breitengrad" },
    { field: "longitude", header: "Längengrad" },
    { field: "maxDeliveryDistance", header: "Lieferradius" },
    { field: "isOpen", header: "Geöffnet" },
  ];

  if (isAdmin) {
    columns.push({ field: "delete", header: "" });
  }

  useEffect(() => {
    const setMerchantData = async () => {
      const response = await getMerchants();
      setMerchants(response);
    };
    setMerchantData();
  }, []);

  const refetch = async () => {
    const response = await getMerchants();
    setMerchants(response);
  };
  const deleteMerchantByID = async (merchantId: number) => {
    await deleteMerchant(merchantId);
    const newMerchants = merchants.filter(({ id }) => id !== merchantId);
    setMerchants(newMerchants);
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };

    // @ts-ignore
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const textTemplate = (field: any, rowData: any) => {
    if (field === "name")
      return (
        <Link
          to={`/merchants/${rowData.id}`}
          className="flex justify-between text-secondary-1"
        >
          {rowData[field]} <p className="pi pi-chevron-right" />
        </Link>
      );
    return <p className={"text-secondary-1"}>{rowData[field]}</p>;
  };
  const booleanTemplate = (field: any, rowData: any) => {
    return rowData[field] ? (
      <p className={"text-customgreen"}>geöffnet</p>
    ) : (
      <p className={"text-red-600"}>geschlossen</p>
    );
  };

  const bodyTemplate = (field: string, rowData: MerchantType) => {
    switch (field) {
      case "isOpen":
        return booleanTemplate(field, rowData);
      case "delete":
        return deleteTemplate(rowData);
      default:
        return textTemplate(field, rowData);
    }
  };
  const deleteTemplate = (rowData: any) => {
    return (
      <Button
        className="pi pi-trash text-secondary-1"
        onClick={() => deleteMerchantByID(rowData["id"])}
      />
    );
  };
  const createMerchant = () => {
    setCurrentMerchant(undefined);
    setOpenModal(true);
  };

  const renderHeader = () => {
    return (
      <div className="w-full flex flex-row justify-between">
        <span className="p-input-icon-left ">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Suchen"
            className="p-2 pl-8"
          />
        </span>
        {isAdmin && <Button onClick={createMerchant}>Lager anlegen</Button>}
      </div>
    );
  };
  const header = renderHeader();

  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <div className="card">
      <CreateModal
        refetch={refetch}
        merchant={currentMerchant}
        isOpen={openModal}
        closeModal={closeModal}
      />
      <DataTable
        emptyMessage="Keine Lager gefunden"
        value={merchants || []}
        editMode="cell"
        className={"mt-4"}
        stripedRows
        header={header}
        size={"small"}
        removableSort
        globalFilterFields={["name"]}
        filters={filters}
      >
        {columns.map(({ field, header }) => {
          return (
            <Column
              key={field}
              field={field}
              header={header}
              style={{ width: "25%" }}
              body={(rowData) => bodyTemplate(field, rowData)}
              sortable
            />
          );
        })}
      </DataTable>
    </div>
  );
};
export default Merchants;
