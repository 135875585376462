import React, { useState, FC, FormEvent } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ROUTES_DEFINITIONS } from "../../../router/routerConfig";
import Logo from "../../../components/Logo";
const Index: FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate();
  const cookies = new Cookies();
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    let expires = new Date();
    expires.setHours(expires.getHours() + 8);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_PATH}/api/dashboard/auth/login`,
        { email, password }
      );
      cookies.set("MIDNIGHT_DELIVERY_TOKEN", response.data.accessToken, {
        path: "/",
        expires,
      });
      navigate(ROUTES_DEFINITIONS.Dashboard);
    } catch (e) {
      toast.error("E-mail oder Passwort ist falsch", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    }
  };
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <ToastContainer autoClose={2000} />
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <Logo />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-primary">
          Melde dich bei deinem Konto an
        </h2>
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form
          className="space-y-6"
          action="auth/Login#"
          method="POST"
          onSubmit={handleSubmit}
        >
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-secondary-1"
            >
              E-Mail-Adresse
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                value={email.trim()}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
                required
                className="block w-full rounded-md border-0  p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-midblue sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-secondary-1"
              >
                Passwort
              </label>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                value={password.trim()}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
                required
                className="block w-full rounded-md border-0  p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-midblue sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-secondary-1 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-midblue"
            >
              Los geht's
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Index;
