import React, { useCallback, useEffect, useState } from "react";
import {
  ProductListItem,
  activateMerchantProduct,
  detachProductFromMerchant,
  getStockLevels,
  updateMerchantProduct,
} from "../../../../api/products";
import AttachProductModal from "../../Products/attachModal";
import { DataTable } from "primereact/datatable";
import {
  Column,
  ColumnEditorOptions,
  ColumnEvent,
  ColumnProps,
} from "primereact/column";
import { convertIntToPrice } from "../../../../helpers/converters";
import { Button } from "primereact/button";
import {
  InputNumber,
  InputNumberValueChangeEvent,
} from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { categories } from "../../../../helpers/values";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import BulkProductActionsModal from "./BulkProductActionsModal";
import { createJusteatMenu } from "../../../../api/justeat";
import "../merchant.css";

interface Props {
  isAdmin: boolean;
  merchantId: number;
  justEatConnected: boolean;
}

const MerchantProducts: React.FC<Props> = ({
  isAdmin,
  merchantId,
  justEatConnected,
}) => {
  const [products, setProducts] = useState<ProductListItem[]>();
  const [selectedProducts, setSelectedProducts] = useState<ProductListItem[]>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [csvModal, setCSVModal] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<string | string[]>([]);
  const [isActive, setIsActive] = useState<boolean | null>(null);
  const [sort, setSort] = useState({ field: "", order: "DESC" });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 250,
    pageCount: 0,
    total: 0,
  });
  const [filters, setFilters] = useState<Record<string, unknown>>({
    merchantId,
  });
  const [search, setSearch] = useState<string>("");

  const setTagFilter = (e: MultiSelectChangeEvent) => {
    const value = e.target.value;
    setSelectedTags(value);
    setFilters({ ...filters, tags: value });
  };

  const setActiveFilter = (e: MultiSelectChangeEvent) => {
    const value = e.target.value;
    setIsActive(value);
    setFilters({ ...filters, isActive: value });
  };

  const setProductData = useCallback(async () => {
    setIsLoading(true);
    const response = await getStockLevels({ filters, pagination, sort });
    if (response) {
      const { data, ...paginationPayload } = response;
      setPagination(paginationPayload);
      setProducts(data);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sort]);

  useEffect(() => {
    setProductData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setProductData]);

  const createMenuJusteat = async () => {
    const payload = {
      merchantId,
      items: selectedProducts?.map(({ sku }) => sku),
    };
    await createJusteatMenu(payload);
  };

  useEffect(() => {
    const setProductData = async () => {
      const response = await getStockLevels({ filters, pagination });
      if (response) {
        const { data, ...paginationPayload } = response;
        setPagination(paginationPayload);
        setProducts(data);
      }
    };
    if (pagination.total > 10 && products?.length && products?.length < 10)
      setProductData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.total, products?.length]);

  const setSortFkt = async (event: any) => {
    setSort({
      field: event.sortField,
      order: event.sortOrder === 1 ? "ASC" : "DESC",
    });
  };

  const setPage = async (event: any) => {
    const response = await getStockLevels({
      filters,
      pagination: { ...pagination, page: event.page + 1, perPage: event.rows },
    });
    if (response) {
      const { data, ...paginationPayload } = response;
      setPagination(paginationPayload);
      setProducts(data);
    }
  };

  const refetchProducts = () => {
    setPagination({
      page: 1,
      pageCount: pagination.pageCount,
      perPage: 250,
      total: pagination.total,
    });
  };

  const deleteProduct = async (id: string) => {
    if (merchantId) {
      await detachProductFromMerchant(id);
      const newProducts = products?.filter((product) => product.id !== id);
      const newSelectedProduct = selectedProducts?.filter(
        (product) => product.id !== id
      );
      setProducts(newProducts);
      setSelectedProducts(newSelectedProduct);
    }
  };

  const deleteMultipleProducts = async () => {
    if (merchantId) {
      await selectedProducts?.forEach(
        async (product) => await detachProductFromMerchant(`${product.id}`)
      );
      const newProducts = products?.filter(
        (product) => !selectedProducts?.includes(product)
      );
      setProducts(newProducts);
      setSelectedProducts([]);
    }
  };

  const acceptDelete = async (id: string) => {
    await deleteProduct(id);
  };

  const confirmDelete = (id: string) => {
    confirmDialog({
      message: "Sind Sie sicher, dass Sie dieses Produkt löschen möchten?",
      header: "Bestätigen",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Ja",
      rejectLabel: "Nein",
      acceptClassName: "p-button-danger p-2",
      rejectClassName: "p-button-secondary p-2",
      accept: () => acceptDelete(id),
      reject: () => null,
    });
  };

  const confirmSelectionDelete = () => {
    confirmDialog({
      message: "Sind Sie sicher, dass Sie diese Produkte löschen möchten?",
      header: "Bestätigen",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Ja",
      rejectLabel: "Nein",
      acceptClassName: "p-button-danger p-2",
      rejectClassName: "p-button-secondary p-2",
      accept: () => deleteMultipleProducts(),
      reject: () => null,
    });
  };

  const activateProduct = async (id: string) => {
    const newProducts = products?.map((product) => {
      if (product.id === id) return { ...product, isActive: !product.isActive };
      return product;
    });
    setProducts(newProducts);
    await activateMerchantProduct(id);
  };

  const bodyTemplate = (rowData: any, field: string) => {
    const shopifyUrl = rowData["shopifyUrl"];
    switch (field) {
      case "featuredImage":
        return (
          <img
            src={rowData["featuredImage"]}
            alt={rowData["title"]}
            className="h-10 w-10"
          />
        );
      case "price":
        return (
          <p className={"text-secondary-1 whitespace-nowrap"}>
            {convertIntToPrice(rowData[`${field}`])}
          </p>
        );
      case "tags":
        return (
          <p className={"text-secondary-1"}>{rowData["tags"]?.join(", ")}</p>
        );
      case "isActive":
        return rowData["isActive"] ? (
          <Button
            icon="pi pi-pause"
            className="bg-green-500 text-white"
            onClick={async () => await activateProduct(rowData["id"])}
          />
        ) : (
          <Button
            icon="pi pi-play"
            className="bg-red-500 text-white"
            onClick={async () => await activateProduct(rowData["id"])}
          />
        );
      case "delete":
        return (
          <Button
            className="pi pi-trash text-secondary-1"
            onClick={() => confirmDelete(rowData["id"])}
          />
        );
      case "shopify":
        if (!shopifyUrl) return;
        return (
          <Button
            text
            onClick={() => window.open(`${rowData["shopifyUrl"]}`)}
            label="Zu Shopify"
            icon="pi pi-angle-right"
            iconPos="right"
            className={"text-secondary-1"}
          />
        );
      default:
        return <p className={"text-secondary-1"}>{rowData[`${field}`]}</p>;
    }
  };

  const onCellEditComplete = async (e: ColumnEvent) => {
    let { rowData, newValue, field } = e;
    rowData[field] = newValue;
    if (merchantId) {
      const updatedProduct = await updateMerchantProduct(rowData.id, {
        price: rowData.price,
        amount: rowData.amount,
        targetAmount: rowData.targetAmount,
        triggerAmount: rowData.triggerAmount,
      });
      if (updatedProduct) {
        const newProducts = products?.map((product) => {
          if (product.id === `${updatedProduct.id}`)
            return {
              ...product,
              price: updatedProduct.price,
              amount: updatedProduct.amount,
              targetAmount: updatedProduct.targetAmount,
              triggerAmount: updatedProduct.triggerAmount,
            };
          return product;
        });
        setProducts(newProducts);
      }
    }
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
    setFilters({ ...filters, search: value });
  };

  const tagFilter = () => {
    return (
      <span className="p-input-icon-left ">
        <i className="pi pi-tags" />
        <MultiSelect
          value={selectedTags}
          options={categories}
          onChange={setTagFilter}
          placeholder="Tags"
          className="p-2 pl-8"
        />
      </span>
    );
  };

  const activeOptions = [
    { label: "Aktiv", value: true },
    { label: "Inaktiv", value: false },
    { label: "Alle", value: null },
  ];
  const activeFilter = () => {
    return (
      <Dropdown
        value={isActive}
        options={activeOptions}
        onChange={setActiveFilter}
        placeholder="Alle"
      />
    );
  };

  const titleFilter = () => {
    return (
      <span className="p-input-icon-left ">
        <i className="pi pi-search" />
        <InputText
          value={search}
          onChange={onSearchChange}
          placeholder="Suchen"
          className="p-2 pl-8"
        />
      </span>
    );
  };

  const priceEditor = (options: ColumnEditorOptions) => {
    return options ? (
      <InputNumber
        value={options.value}
        onValueChange={(e: InputNumberValueChangeEvent) => {
          const newValue = e.value && e.value > 0 ? e.value : 0;
          options.editorCallback?.(newValue);
        }}
      />
    ) : (
      <></>
    );
  };

  let columns: ColumnProps[] = [];

  if (isAdmin) {
    columns = [{ selectionMode: "multiple" }];
  }

  columns.push(
    { field: "featuredImage", header: "" },
    {
      field: "title",
      header: "Produktname",
      sortable: true,
      filter: true,
      filterElement: titleFilter,
    }
    // {
    //   field: "amount",
    //   header: "Auf Lager",
    //   onCellEditComplete,
    //   sortable: true,
    //   editor: priceEditor,
    // },
  );

  if (isAdmin) {
    columns.push(
      {
        field: "price",
        header: "Preis",
        onCellEditComplete,
        sortable: true,
        editor: priceEditor,
      },
      {
        field: "tags",
        header: "Tags",
        filter: true,
        filterElement: tagFilter,
      }
    );
  }

  columns.push({
    field: "isActive",
    header: "Aktiv",
    filter: true,
    filterElement: activeFilter,
  });

  if (isAdmin) {
    columns.push({ field: "shopify", header: "Shopify Link" });
    columns.push({ field: "delete", header: "" });
  }
  return (
    <div className="card">
      {!!merchantId && !!openModal && (
        <AttachProductModal
          merchantId={merchantId}
          closeModal={() => setOpenModal(false)}
          refetch={refetchProducts}
        />
      )}
      {!!merchantId && (
        <BulkProductActionsModal
          merchantId={merchantId}
          isOpen={csvModal}
          closeModal={() => setCSVModal(false)}
          refetch={refetchProducts}
        />
      )}
      <ConfirmDialog />
      <div className="border-b border-gray-200 pb-5">
        <div className="mt-2 flex items-center justify-between sm:flex-nowrap">
          <div className="flex">
            <div className="ml-4 mt-2 justify-items-end flex gap-2 flex-shrink-0">
              {isAdmin && (
                <>
                  <button
                    type="button"
                    className="mr-2 relative inline-flex items-center rounded-md bg-midblue border-midblue border px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-transparent hover:text-midblue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-midblue"
                    onClick={() => setCSVModal(true)}
                  >
                    Bulk Aktionen
                  </button>
                  <button
                    type="button"
                    className="mr-2 relative inline-flex items-center rounded-md bg-customblue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-midblue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-midblue"
                    onClick={() => setOpenModal(true)}
                  >
                    Produkt hinzufügen
                  </button>
                </>
              )}
              <Button icon="pi pi-refresh" onClick={setProductData} />
              {selectedProducts && selectedProducts.length > 0 && isAdmin && (
                <Button
                  icon="pi pi-trash"
                  label="Ausgewählte Produkte löschen"
                  onClick={() => confirmSelectionDelete()}
                />
              )}
              {justEatConnected &&
                selectedProducts &&
                selectedProducts.length > 0 &&
                isAdmin && (
                  <Button
                    icon="pi pi-external-link"
                    label="Justeat Menu erstellen"
                    onClick={() => createMenuJusteat()}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
      <DataTable
        lazy
        value={products || []}
        editMode="cell"
        onPage={async (event) => await setPage(event)}
        selectionMode={"checkbox"}
        selection={selectedProducts || []}
        onSelectionChange={(e) => setSelectedProducts(e.value)}
        paginator
        rowsPerPageOptions={[10, 25, 50, 100, 250]}
        totalRecords={pagination.total}
        rows={pagination.perPage}
        emptyMessage="Keine Produkte gefunden"
        filterDisplay="row"
        onSort={setSortFkt}
        sortField={sort.field}
        sortOrder={sort.order === "ASC" ? 1 : -1}
        loading={isLoading}
      >
        {columns.map(
          ({
            field,
            header,
            onCellEditComplete,
            editor,
            filter,
            filterElement,
            sortable,
            selectionMode,
          }) => (
            <Column
              key={field}
              field={field}
              header={header}
              onCellEditComplete={onCellEditComplete}
              editor={editor}
              selectionMode={selectionMode}
              body={field ? (rowData) => bodyTemplate(rowData, field) : null}
              filter={filter}
              filterElement={filterElement}
              sortable={sortable}
            />
          )
        )}
      </DataTable>
    </div>
  );
};

export default MerchantProducts;
