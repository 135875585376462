import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
} from ".";
import { ProductVariant } from "./products";

export interface Hours {
  start_time: string;
  end_time: string;
}
export interface OpeningHours {
  monday: Hours[];
  tuesday: Hours[];
  wednesday: Hours[];
  thursday: Hours[];
  friday: Hours[];
  saturday: Hours[];
  sunday: Hours[];
}
export interface MerchantType {
  id?: number;
  name: string;
  latitude: number;
  longitude: number;
  maxDeliveryDistance: number;
  isOpen: boolean;
  country: string;
  weclappId: string;
  justeatIds: string[];
  orderlordId: string;
  orderlordVenueId: string;
  pushToOrderlord: boolean;
  syncOrderlord: boolean;
  lastOrderSync: Date;
  reorderDay: number;
  openingHours: OpeningHours;
  je_openingHours: OpeningHours;
  minOrderPrice: number;
  orderDistanceFactor: number;
  orderTimeFactor: number;
  distanceInM?: number;
  durationInS?: number;
  deliveryCost?: number;
  minPrice?: number;
  contractor?: string;
}
export interface MerchantOrderType {
  id: number;
  merchantId: number;
  weclappId: string;
  createdAt: Date;
  updatedAt: Date;
  status: string;
  merchant: MerchantType;
  items: MerchantOrderItemType[];
}
export interface MerchantOrderItemType {
  id: number;
  price: number;
  quantity: number;
  discount: number;
  orderId: number;
  isMerchantProduct: boolean;
  variantId: number;
  variant: ProductVariant;
}
export const getMerchants = async () => {
  const merchants = (await getRequest(
    "api/dashboard/merchants/all"
  )) as MerchantType[];
  return merchants?.filter(
    (merchant: MerchantType) => !merchant.name.includes("XXX")
  );
};

export const getMerchantZipcodes = async (id: number) => {
  const data = await getRequest(`api/dashboard/merchants/${id}/zipcodes`, {});
  return data as any;
};

export const createMerchantZipcodes = async (
  id: number,
  payload: Record<string, any>
) => {
  const data = await postRequest(
    `api/dashboard/merchants/${id}/zipcodes`,
    payload
  );
  return data as any;
};

export const bulkUpdateMerchantZipcodes = async (
  merchantId: number,
  payload: Array<Record<string, any>>
) => {
  const data = await putRequest(
    `api/dashboard/merchants/${merchantId}/zipcodes/bulk`,
    payload
  );
  return data as any;
};

export const updateMerchantZipcodes = async (
  merchantId: number,
  id: number,
  payload: Record<string, any>
) => {
  const data = await putRequest(
    `api/dashboard/merchants/${merchantId}/zipcodes/${id}`,
    payload
  );
  return data as any;
};

export const deleteMerchantZipcodes = async (
  merchantId: number,
  id: number
) => {
  const data = await deleteRequest(
    `api/dashboard/merchants/${merchantId}/zipcodes/${id}`
  );
  return data;
};

export const getMerchantByAddress = async (params: Record<string, unknown>) => {
  const merchants = await getRequest("api/dashboard/merchants", params);
  return merchants as { delivery: MerchantType[]; pickup: MerchantType[] };
};

export const orderlordSyncMerchant = async (
  id: number,
  syncOrderlord: boolean,
  lastOrderSync: string
) => {
  const merchant = await patchRequest(
    `api/dashboard/merchants/${id}/orderlord-sync`,
    { syncOrderlord, lastOrderSync }
  );
  return merchant as MerchantType;
};

export const getMerchantOrders = async (
  id: number,
  params?: Record<string, unknown>
) => {
  const orders = await getRequest(
    `api/dashboard/merchants/${id}/orders`,
    params
  );
  return orders as MerchantOrderType[];
};

export const createMerchantOrder = async (merchantId: number) => {
  const order = await putRequest(
    `api/dashboard/merchants/${merchantId}/orders/reset`,
    {}
  );
  return order as MerchantOrderType;
};

export const updateMerchantOrder = async (
  merchantId: number,
  orderId: number,
  payload: Record<string, unknown>
) => {
  const order = await putRequest(
    `api/dashboard/merchants/${merchantId}/orders/${orderId}`,
    payload
  );
  return order as MerchantOrderType;
};

export const fulfillMerchantOrder = async (
  merchantId: number,
  orderId: number
) => {
  const order = await putRequest(
    `api/dashboard/merchants/${merchantId}/orders/${orderId}/fullfill`,
    {}
  );
  return order as MerchantOrderType;
};

export const sendMerchantOrder = async (orderId: number) => {
  const order = await patchRequest(
    `api/dashboard/weclapp/merchants/sendOrder/${orderId}`,
    {}
  );
  return order as MerchantOrderType;
};

export const updateMerchantOrderItem = async (
  merchantId: number,
  orderId: number,
  itemId: number,
  payload: Record<string, unknown>
) => {
  const item = await putRequest(
    `api/dashboard/merchants/${merchantId}/orders/${orderId}/items/${itemId}`,
    payload
  );
  return item as MerchantOrderItemType;
};

export const getMerchant = async (id: string) => {
  const merchant = await getRequest(`api/dashboard/merchants/${id}`);
  return merchant as MerchantType;
};

export const updateMerchant = async (
  payload: MerchantType
): Promise<MerchantType | undefined> => {
  const merchant = await putRequest(`api/dashboard/merchants/${payload.id}`, {
    ...payload,
  });
  return merchant as MerchantType;
};

export const openCloseMerchant = async (
  id: number,
  isOpen: boolean
): Promise<MerchantType | undefined> => {
  const merchant = await putRequest(`api/dashboard/merchants/${id}/open`, {
    isOpen,
  });
  return merchant as MerchantType;
};

export const createMerchant = async (payload: MerchantType) => {
  const merchant = await postRequest("api/dashboard/merchants", payload);
  return merchant as MerchantType;
};

export const deleteMerchant = async (id: number): Promise<void> => {
  await deleteRequest(`api/dashboard/merchants/${id}`);
};
