export const updateMenuWithSkus = (menu: any) => {
  return {
    ...menu,
    categories: updateCategoriesWithProductInfo(menu.categories),
  };
};
export const updateCategoriesWithSkus = (categories: any) => {
  return categories.map((category: any) => {
    if (category?.products?.length && category.products[0]?.sku) {
      const skus = category.products.map((item: any) => item.sku);
      return { ...category, products: skus }; // Replace products with SKUs
    }
    return category;
  });
};

export const updateCategoriesWithProductInfo = (categories: any) => {
  return categories.map((category: any) => {
    if (category?.products?.length && category.products[0]?.sku) {
      const skus = category.products.map((item: any) => ({
        sku: item.sku,
        name: item.variant.product.title,
      }));
      return { ...category, products: skus }; // Replace products with SKUs
    }
    return category;
  });
};

export const categoriesToCSVArray = (categories: any) => {
  const categoriesWithProductInfo = updateCategoriesWithProductInfo(categories);
  const categoryCSV: Array<{ category: string; sku: string; name: string }> =
    [];
  categoriesWithProductInfo.forEach((category: any) => {
    const products = category.products.map((product: any) => ({
      ...product,
      category: category.name,
    }));
    categoryCSV.push(...products);
  });
  return categoryCSV;
};
